import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Box } from '@mui/material';
import React from 'react';
import styles from '../assets/css/components/FileViewer.module.css';
import { getFileType } from '../utils/ViewFileHelper';

function FileViewer({ selectedFileName, fileURL }) {
  console.log('FileViewer called');
  console.log('fileURL:', fileURL);
  const fileType = getFileType(selectedFileName);

  const baseStyle = {
    width: '98%',
    height: '98%',
  };

  console.log('fileType:', fileType);

  const docViewerStyle =
    fileType === 'doc' || fileType === 'docx'
      ? { ...baseStyle, maxHeight: '98%' }
      : fileType === 'pdf'
        ? { ...baseStyle, maxHeight: '85vh', overflow: 'auto' }
        : baseStyle; // Default style if not .doc or .pdf

  const documents = [
    {
      uri: fileURL,
      fileType: fileType,
    },
  ];

  const reqHeaders = {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };

  return (
    <Box className={styles.viewContainer}>
      <div className={styles.fileName}>{selectedFileName}</div>
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={documents}
        requestHeaders={reqHeaders}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
        style={docViewerStyle}
      />
    </Box>
  );
}

export default FileViewer;
