import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const validateBasicProjectInformation = (data) => {
  let errors = {};

  if (!data.startDate) {
    errors.startDate = 'You must select a start date for the project.';
  }
  if (!data.duration) {
    errors.duration = 'You must provide the duration of the project.';
  }
  if (!data.funding) {
    errors.funding = 'You must select the funding source for the project.';
  }

  return errors;
};

const BasicProjectInformationSection = ({ formData, setFormData }) => {
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      basicProjectInformation: {
        ...prevFormData.basicProjectInformation,
        [name]: value,
      },
      errors: {
        ...prevFormData.errors,
        basicProjectInformation: {
          ...prevFormData.errors.basicProjectInformation,
          [name]: '', // Clear the error for the respective field
        },
      },
    }));
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      basicProjectInformation: {
        ...prevFormData.basicProjectInformation,
        [name]: parseInt(value, 10),
      },
      errors: {
        ...prevFormData.errors,
        basicProjectInformation: {
          ...prevFormData.errors.basicProjectInformation,
          [name]: '', // Clear the error for duration
        },
      },
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Basic Project Information</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography>Start date of the Project:</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="startDate"
            value={formData.basicProjectInformation.startDate || ''}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="AgreementExecuted"
              control={<Radio />}
              label="Date when the Agreement is executed"
            />
            <FormControlLabel
              value="ProjectCommenced"
              control={<Radio />}
              label="The date when the Project has started or will commence"
            />
          </RadioGroup>
          {formData.errors.basicProjectInformation &&
            formData.errors.basicProjectInformation.startDate && (
              <Typography color="error">
                {formData.errors.basicProjectInformation.startDate}
              </Typography>
            )}
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth variant="outlined">
          <TextField
            label="Duration of Project (months)"
            type="number"
            name="duration"
            min="1"
            value={formData.basicProjectInformation.duration || ''}
            onChange={handleDurationChange}
            required
            error={
              formData.errors.basicProjectInformation &&
              !!formData.errors.basicProjectInformation.duration
            }
            helperText={
              formData.errors.basicProjectInformation &&
              formData.errors.basicProjectInformation.duration
            }
          />
        </FormControl>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Funding:</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="funding"
            value={formData.basicProjectInformation.funding || ''}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="CollaboratorFunding"
              control={<Radio />}
              label="Collaborator is providing funding to NUS"
            />
            <FormControlLabel
              value="NUSFunding"
              control={<Radio />}
              label="NUS is providing funding to Collaborator"
            />
            <FormControlLabel
              value="NilFunding"
              control={<Radio />}
              label="Nil, only involve in-kind contributions from all parties"
            />
          </RadioGroup>
          {formData.errors.basicProjectInformation &&
            formData.errors.basicProjectInformation.funding && (
              <Typography color="error">
                {formData.errors.basicProjectInformation.funding}
              </Typography>
            )}
        </FormControl>
      </Box>
    </Box>
  );
};

export { BasicProjectInformationSection, validateBasicProjectInformation };
