import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';

const validateRCAorCRA = (data) => {
  let errors = {};
  if (!data.agreementType) {
    errors.agreementType = 'You must select an agreement type.';
  }
  return errors;
};

const RCAorCRASection = ({ formData, setFormData }) => {
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      rcaOrCra: {
        ...prevFormData.rcaOrCra,
        [name]: value,
      },
      errors: {
        ...prevFormData.errors,
        rcaOrCra: {
          ...prevFormData.errors.rcaOrCra,
          [name]: '', // Clear the error for agreementType
        },
      },
    }));
  };

  const agreementType = formData.rcaOrCra.agreementType || '';

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Agreement Type</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" gutterBottom>
          Do you need a Research Collaboration Agreement (RCA) or a Contract
          Research Agreement (CRA)? <span style={{ color: 'red' }}>*</span>
        </Typography>
        <FormControl component="fieldset" required>
          <RadioGroup
            name="agreementType"
            value={agreementType}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="RCA" control={<Radio />} label="RCA" />
            <FormControlLabel value="CRA" control={<Radio />} label="CRA" />
          </RadioGroup>
          {formData.errors.rcaOrCra.agreementType && (
            <Typography color="error">
              {formData.errors.rcaOrCra.agreementType}
            </Typography>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export { RCAorCRASection, validateRCAorCRA };
