import CONSTANTS from '../config/CONSTANTS';
import {
  getFileContentByDocId,
  // reqQueryfetchFileContent,
} from '../utils/Helperfunction';

export const getFileType = (fn) => {
  fn = fn.toLowerCase();
  const parts = fn.split('.');
  return parts[parts.length - 1];
};

export const fetchFileUrl = async (file) => {
  try {
    const fileType = file.fileName.split('.').pop();
    const endpoint = CONSTANTS['ENDPOINT_GET_FILE'];
    const response = await getFileContentByDocId(
      endpoint,
      file.docId,
      fileType,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch file content: ${response.statusText}`);
    }

    const { url } = response;

    if (!url) {
      throw new Error('No URL returned from the server');
    }

    return url;
  } catch (error) {
    console.error('Error fetching file:', error);
    return { error: error.message };
  }
};

// export const fetchFileContent = async (fileUrl) => {
//   try {
//     const response = await fetch(fileUrl);
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const content = await response.text();
//     console.log('File content:', content);
//     return content;
//   } catch (error) {
//     console.error('Fetch file content failed:', error);
//     return { error: error.message };
//   }
// };
export const fetchFileContent = async (fileUrl) => {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('content-type');
    if (
      contentType.includes('application/pdf') ||
      contentType.includes('application/msword') ||
      contentType.includes(
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      )
    ) {
      const arrayBuffer = await response.arrayBuffer();
      return arrayBuffer;
    } else {
      const textContent = await response.text();
      return textContent;
    }
  } catch (error) {
    console.error('Fetch file content failed:', error);
    return { error: error.message };
  }
};

export const sanitizeDocxHTML = (html) => {
  const regex = /color\s*:\s*#[a-fA-F0-9]{6}\b/g;
  var htmlNew = html.replace(regex, '');
  const regex2 = /<a\b[^>]*>(.*?)<\/a>/g;
  htmlNew = htmlNew.replace(regex2, '<p>$1</p>');
  return htmlNew;
};

function getUnescapeString(str) {
  // Create a mapping of escaped characters to their unescaped counterparts
  const unescapeMap = {
    '\\n': '\n',
    '\\r': '\r',
    '\\t': '\t',
    '\\"': '"',
    "\\'": "'",
    '\\\\': '\\',
  };

  var resultStr = str.replace(/\\[nrt"\\']/g, (match) => {
    // Replace each found sequence with its mapping from the unescapeMap
    return unescapeMap[match] || match;
  });

  // Use String.replace with a regular expression to find all escaped sequences
  return resultStr;
}

export const fetchCompareData = async (docObj) => {
  if (!docObj) return;
  const docId = docObj.docId;
  let endpoint = CONSTANTS['endpoint_get_file_html'];
  let response;
  const fileType = getFileType(docObj.fileName);
  if (fileType === 'docx') {
    response = await getFileContentByDocId(endpoint, docId, 'docx');
  } else {
    response = await getFileContentByDocId(endpoint, docId, 'pdf');
  }

  if (response && response.ok) {
    // const contentType = response.headers.get('content-type');
    if (fileType === 'docx') {
      const data = await response.text();
      // return getUnescapeString(sanitizeDocxHTML(data));
      return data;
    } else {
      const data = await response.text();
      return data;
    }
  } else {
    console.error('Failed to fetch file content for comparison', docId);
  }
};
