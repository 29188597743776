import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import styles from '../assets/css/components/PromptSelection.module.css';

function PromptSelection({ prompts, onPromptSelect }) {
  return (
    <Box className={styles.container}>
      {prompts.map((prompt) => (
        <Card
          key={prompt.brief}
          onClick={() => onPromptSelect(prompt)}
          className={styles.card}
        >
          <CardActionArea className={styles.cardActionArea}>
            <CardContent className={styles.cardContent}>
              <Typography className={styles.titleText}>
                {prompt.brief}
              </Typography>
              <Typography className={styles.descriptionText}>
                {prompt.prompt}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
}

export default PromptSelection;
