import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import styles from '../assets/css/components/TemplatePopup.module.css';

function TemplatePopup({ open, handleClose, handleSave }) {
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState([
    { question: '', supplementary: '', sourceType: 'text', sourceDetails: '' },
  ]);
  const [supplementing, setSupplementing] = useState([]);
  const [errors, setErrors] = useState({
    templateName: false,
    description: false,
    questions: [{ question: false }],
  });

  const validateInput = () => {
    let isValid = true;

    const questionErrors = questions.map((q) => ({
      question: !q.question.trim(),
      sourceDetails:
        (q.sourceType === 'url' || q.sourceType === 'database') &&
        !q.sourceDetails.trim(),
    }));

    const newErrors = {
      templateName: !templateName.trim(),
      description: !description.trim(),
      questions: questionErrors,
    };

    isValid =
      !newErrors.templateName &&
      !newErrors.description &&
      !questionErrors.some((q) => q.question || q.sourceDetails);

    setErrors(newErrors);
    return isValid;
  };

  const handleConfirm = () => {
    if (validateInput()) {
      handleSave(templateName, description, questions);
      handleClose();
    }
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = [...questions];
    newQuestions[index][key] = value;
    setQuestions(newQuestions);

    const newQuestionErrors = [...errors.questions];
    newQuestionErrors[index] = { ...newQuestionErrors[index], [key]: !value };
    setErrors({ ...errors, questions: newQuestionErrors });
  };

  const handleAddQuestion = () => {
    if (questions.length < 5) {
      setQuestions([
        ...questions,
        {
          question: '',
          supplementary: '',
          sourceType: 'text',
          sourceDetails: '',
        },
      ]);
      setErrors({
        ...errors,
        questions: [
          ...errors.questions,
          { question: false, supplementary: false },
        ],
      });
    }
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    const newQuestionErrors = errors.questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
    setErrors({ ...errors, questions: newQuestionErrors });
    setSupplementing(supplementing.filter((i) => i !== index));
  };

  const handleAddSupplement = (index) => {
    setSupplementing([...supplementing, index]);
  };

  const handleRemoveSupplement = (index) => {
    setSupplementing(supplementing.filter((i) => i !== index));
    const newQuestions = [...questions];
    newQuestions[index].supplementary = '';
    newQuestions[index].sourceType = 'text';
    newQuestions[index].sourceDetails = '';
    setQuestions(newQuestions);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '800px',
          padding: '10px',
        },
      }}
    >
      <DialogTitle>Edit Template</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <TextField
          autoFocus
          margin="normal"
          id="templateName"
          label="Template Name"
          type="text"
          fullWidth
          value={templateName}
          onChange={(e) => {
            setTemplateName(e.target.value);
            setErrors({ ...errors, templateName: false });
          }}
          error={errors.templateName}
          helperText={errors.templateName ? 'This field is required' : ''}
        />
        <TextField
          margin="normal"
          id="description"
          label="Description/Remarks"
          type="text"
          fullWidth
          multiline
          minRows={4}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setErrors({ ...errors, description: false });
          }}
          error={errors.description}
          helperText={errors.description ? 'This field is required' : ''}
        />
        <List>
          {questions.map((item, index) => (
            <ListItem key={index} className={styles.questionListItem}>
              <div className={styles.textFieldRow}>
                <TextField
                  className={styles.textField}
                  label={`Question ${index + 1}`}
                  type="text"
                  fullWidth
                  value={item.question}
                  onChange={(event) =>
                    handleQuestionChange(index, 'question', event.target.value)
                  }
                  error={errors.questions[index].question}
                  helperText={
                    errors.questions[index].question
                      ? 'This field is required'
                      : ''
                  }
                />
                <Button
                  onClick={() =>
                    supplementing.includes(index)
                      ? handleRemoveSupplement(index)
                      : handleAddSupplement(index)
                  }
                  className={styles.supplementButton}
                >
                  {supplementing.includes(index)
                    ? 'Cancel Supplement'
                    : 'Supplement'}
                </Button>
                {questions.length > 1 && (
                  <IconButton
                    onClick={() => handleRemoveQuestion(index)}
                    className={styles.removeIcon}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </div>
              {supplementing.includes(index) && (
                <div className={styles.supplementRow}>
                  <Select
                    className={styles.selectField}
                    value={item.sourceType}
                    displayEmpty
                    onChange={(event) =>
                      handleQuestionChange(
                        index,
                        'sourceType',
                        event.target.value,
                      )
                    }
                    sx={{ minWidth: '150px' }}
                  >
                    <MenuItem value="text">Plain Text</MenuItem>
                    <MenuItem value="url">Web Search (URL)</MenuItem>
                    <MenuItem value="database">Database Connection</MenuItem>
                  </Select>
                  {item.sourceType !== 'text' && (
                    <TextField
                      className={styles.textField}
                      label={
                        item.sourceType === 'url'
                          ? 'URL'
                          : 'Database Credentials'
                      }
                      type="text"
                      fullWidth
                      value={item.sourceDetails}
                      onChange={(event) =>
                        handleQuestionChange(
                          index,
                          'sourceDetails',
                          event.target.value,
                        )
                      }
                      sx={{ minWidth: '200px' }}
                    />
                  )}
                  {item.sourceType === 'text' && (
                    <TextField
                      className={styles.textField}
                      label="Supplementary Text"
                      type="text"
                      fullWidth
                      multiline
                      value={item.supplementary}
                      onChange={(event) =>
                        handleQuestionChange(
                          index,
                          'supplementary',
                          event.target.value,
                        )
                      }
                      sx={{ minWidth: '200px' }}
                    />
                  )}
                </div>
              )}
            </ListItem>
          ))}
          {questions.length < 5 && (
            <ListItem className={styles.addQuestionButton}>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddQuestion}
              >
                Add Question
              </Button>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="primary">
          Confirm Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplatePopup;
