import { Box, Button } from '@mui/material';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import styles from '../assets/css/pages/RCAFormPage.module.css';
import Header from '../components/Header';
import {
  BasicProjectInformationSection,
  validateBasicProjectInformation,
} from '../components/forms/BasicProjectInformationSection';
import {
  CollaboratorInformationSection,
  validateCollaboratorInformation,
} from '../components/forms/CollaboratorInformationSection';
import {
  PIInformationSection,
  validatePIInformation,
} from '../components/forms/PIInformationSection';
import {
  RCAorCRASection,
  validateRCAorCRA,
} from '../components/forms/RCAorCRASection';
import {
  ResearchAreaSection,
  validateResearchArea,
} from '../components/forms/ResearchAreaSection';

function RCAFormPage() {
  const [formData, setFormData] = useState({
    rcaOrCra: {},
    piInformation: {},
    collaboratorInformation: [
      { entityName: '', country: '', abbreviation: '' },
    ],
    researchArea: {
      value: '',
      label: '',
    },
    basicProjectInformation: {},
    // confidentialInformation: {},
    agreementType: '',
    errors: {
      rcaOrCra: {},
      piInformation: {},
      collaboratorInformation: [{}],
      researchArea: {},
      basicProjectInformation: {},
    },
  });
  const [currentSection, setCurrentSection] = useState(0);
  const sections = [
    { name: 'RCA or CRA', component: RCAorCRASection },
    { name: 'PI Information', component: PIInformationSection },
    {
      name: 'Collaborator Information',
      component: CollaboratorInformationSection,
    },
    { name: 'Research Area', component: ResearchAreaSection },
    {
      name: 'Basic Project Information',
      component: BasicProjectInformationSection,
    },
  ];

  const handleNext = () => {
    if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
    }
  };

  const handlePrev = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };

  const handleSubmit = () => {
    const rcaCraErrors = validateRCAorCRA(formData.rcaOrCra);
    const piInformationErrors = validatePIInformation(formData.piInformation);
    const collaboratorErrors = validateCollaboratorInformation(
      formData.collaboratorInformation,
    );
    const researchAreaErrors = validateResearchArea(formData.researchArea);
    const basicProjectInformationErrors = validateBasicProjectInformation(
      formData.basicProjectInformation,
    );

    const newErrors = {
      rcaOrCra: rcaCraErrors,
      piInformation: piInformationErrors,
      collaboratorInformation: collaboratorErrors,
      researchArea: researchAreaErrors,
      basicProjectInformation: basicProjectInformationErrors,
    };

    const hasErrors = Object.keys(newErrors).some((key) => {
      if (Array.isArray(newErrors[key])) {
        return newErrors[key].some((error) => Object.keys(error).length > 0);
      }
      return Object.keys(newErrors[key]).length > 0;
    });

    if (hasErrors) {
      setFormData((prevState) => ({
        ...prevState,
        errors: newErrors,
      }));

      console.log('Errors');
      console.log(newErrors);
    } else {
      // Proceed to submit the form data as there are no errors
      console.log('Form data is valid, proceed with submission:', formData);

      const formDataToSave = {
        ...formData,
        errors: {}, // Clear errors on successful validation
      };

      // Save form data to JSON file
      const blob = new Blob([JSON.stringify(formDataToSave, null, 2)], {
        type: 'application/json',
      });
      saveAs(blob, 'formData.json');

      setFormData((prevState) => ({
        ...prevState,
        errors: {}, // Clear errors on successful validation
      }));
    }
  };

  const CurrentComponent = sections[currentSection].component;

  return (
    <Box className={styles.pageContainer}>
      <Header
        pageTitle="Research Collaboration Agreement (RCA) / Contract Research Agreement (CRA)"
        showBackHome={true}
      />
      <Box className={styles.mainContainer}>
        <Box className={styles.navigationContainer}>
          <Box className={styles.navSection}>
            {sections.map((section, index) => (
              <Box
                key={index}
                onClick={() => setCurrentSection(index)}
                className={styles.navItem}
              >
                {section.name}
              </Box>
            ))}
          </Box>
          <Box className={styles.formContainer}>
            <CurrentComponent formData={formData} setFormData={setFormData} />
            <Box>
              {currentSection > 0 && (
                <Button onClick={handlePrev} variant="contained">
                  Prev
                </Button>
              )}
              {currentSection < sections.length - 1 && (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  style={{ marginLeft: '8px' }}
                >
                  Next
                </Button>
              )}
              {currentSection === sections.length - 1 && (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '8px' }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RCAFormPage;
