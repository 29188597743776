import { useFiefAuth } from '@fief/fief/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const fiefAuth = useFiefAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fiefAuth
      .authCallback(
        `${window.location.protocol}//${window.location.host}/callback`,
      )
      .then(() => {
        navigate('/home');
      });
  }, [fiefAuth, navigate]);

  return <p> Logged in</p>;
};

export default Callback;
