
import React from 'react';
import { useFiefAuth } from '@fief/fief/react';
import { useNavigate } from 'react-router-dom';
function LogoutPage() {
    const fiefAuth = useFiefAuth();
    const navigate = useNavigate();
    React.useEffect(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userId');
        fiefAuth.logout(window.location.protocol + window.location.host);
    }, [fiefAuth, navigate]);
    return <div>Logging out...</div>;
}
export default LogoutPage;