import { Spin } from 'antd';
import React from 'react';
import styles from '../assets/css/components/Loading.module.css';

const Loading = () => {
  return (
    <div className={styles.spinWrapper}>
      <Spin size="large" className={styles.spin} />
    </div>
  );
};

export default Loading;
