import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, List, ListItem, ListItemButton, IconButton, Button, TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/pages/CompareFilesPage.module.css';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CONSTANTS from '../config/CONSTANTS';

const CompareFilesPage = () => {
  const location = useLocation();
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file1Name, setFile1Name] = useState(null);
  const [file2Name, setFile2Name] = useState(null);
  const [diffs, setDiffs] = useState([]); // New state variable for diffs
  const [analyzedDiffs, setAnalyzedDiffs] = useState([]); // State for analyzed diffs
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [rulebook, setRulebook] = useState("None"); // State for rulebook
  const [rulebookModalOpen, setRulebookModalOpen] = useState(false); // State for modal
  const [savedRulebooks, setSavedRulebooks] = useState([]); // State for saved rulebooks
  const { doc1, doc2 } = location.state || {};

  var currentDiffIndex = null;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const displayHtml = (html, elementId) => {
    // Add the style tag to the HTML content
    const styledHtml = `<style>
      .diff-highlight {
        background-color: yellow;
      }
    </style>${html}`;
    document.getElementById(elementId).srcdoc = styledHtml;
  };

  const analyzeDiff = async (before, after, summary, rules, index) => {
    try {
      const response = await fetch(CONSTANTS['endpoint_process_diff'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          before: before,
          after: after,
          change_summary: summary,
          rulebook: rules // Pass the rulebook text
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      // Update the analyzedDiffs state with the result
      setAnalyzedDiffs((prevAnalyzedDiffs) => [
        ...prevAnalyzedDiffs.slice(0, index),
        result,
        ...prevAnalyzedDiffs.slice(index + 1)
      ]);
      return result;
    } catch (error) {
      console.error('Error analyzing diff:', error);
      return null; // Or handle the error in a more specific way
    }
  };

  const scrollToDiff = (index) => {
    // Remove highlight from previous diff
    if (currentDiffIndex !== null) {
      removeHighlight(currentDiffIndex);
    }
    currentDiffIndex = index;

    const html1 = document.getElementById('html1');
    const html2 = document.getElementById('html2');

    const iframeDoc1 = html1.contentDocument;
    const iframeDoc2 = html2.contentDocument;

    const elements1 = iframeDoc1.querySelectorAll(`[data-diff-id="${index}"]`);
    const elements2 = iframeDoc2.querySelectorAll(`[data-diff-id="${index}"]`);

    if (elements1.length > 0) {
      elements1[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    elements1.forEach((element) => {
      element.classList.add('diff-highlight');
    });

    if (elements2.length > 0) {
      elements2[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    elements2.forEach((element) => {
      element.classList.add('diff-highlight');
    });
  };

  const removeHighlight = () => {
    if (currentDiffIndex !== null) {
      const html1 = document.getElementById('html1');
      const html2 = document.getElementById('html2');

      const iframeDoc1 = html1.contentDocument;
      const iframeDoc2 = html2.contentDocument;

      const elements1 = iframeDoc1.querySelectorAll(
        `[data-diff-id="${currentDiffIndex}"]`
      );
      const elements2 = iframeDoc2.querySelectorAll(
        `[data-diff-id="${currentDiffIndex}"]`
      );

      elements1.forEach((element) => {
        element.classList.remove('diff-highlight');
      });

      elements2.forEach((element) => {
        element.classList.remove('diff-highlight');
      });
    }
  };

  const analyzeAllDiffs = async () => {
    if (!file1 || !file2) {
      return;
    }

    // setIsLoading(true); // Set loading indicator to true

    try {
      for (let i = 0; i < diffs.length; i++) {
        const diff = diffs[i];
        const analyzeRiskButton = document.getElementById(`analyze-risk-button-${i}`);
        analyzeRiskButton.textContent = 'Analyzing...';
        analyzeRiskButton.disabled = true;
        const response = await analyzeDiff(diff.before, diff.after, diff.summary_plain, rulebook, i);
        analyzeRiskButton.textContent = "Re-analyze";
        analyzeRiskButton.disabled = false;
        if (response) {
          const result = response;
          var risk_level_html = '';
          if (result.risk_level && result.risk_level.toLowerCase().includes('high')) {
            risk_level_html = '<b style="color: red;">High</b>';
          } else if (result.risk_level && result.risk_level.toLowerCase().includes('medium')) {
            risk_level_html = '<b style="color: #ff9933;">Medium</b>';
          } else if (result.risk_level && result.risk_level.toLowerCase().includes('low')) {
            risk_level_html = '<b style="color: green;">Low</b>';
          } else {
            risk_level_html = '<b style="color: grey;">Unknown</b>';
          }
          const analysis = document.getElementById(`analysis-${i}`);
          analysis.innerHTML = `</br><b>Risk level:</b> ${risk_level_html}</br></br><b>Comment:</b> ${result.comment}</br></br>`;
        }
      }
    } catch (error) {
      console.error('Error analyzing all diffs:', error);
    } finally {
      // setIsLoading(false); // Set loading indicator to false
    }
  };


  const fetchSavedRulebooks = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(CONSTANTS['endpoint_fetch_rulebooks'], {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setSavedRulebooks(data.rulebook_ids);
    } catch (error) {
      console.error('Error fetching saved rulebooks:', error);
    }
  };

  const uploadRulebook = async (event) => {
    const file = event.target.files[0];
    const accessToken = localStorage.getItem('accessToken');
    if (file) {
      const formData = new FormData();
      formData.append('files', file, file.fileName);

      try {
        const response = await fetch(CONSTANTS['endpoint_upload_rulebook'], {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setRulebook(data.rulebook_id); // Or appropriate identifier
        fetchSavedRulebooks();
      } catch (error) {
        console.error('Error uploading rulebook:', error);
      }
    }
  };

  const deleteRulebook = async (rulebookId) => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${CONSTANTS['endpoint_delete_rulebook']}/${rulebookId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle rulebook not found
          alert("Rulebook not found");
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }
      // Refresh the list of rulebooks after successful deletion
      fetchSavedRulebooks();
    } catch (error) {
      console.error('Error deleting rulebook:', error);
      alert('Failed to delete rulebook. Please try again.');
    }
  };

  useEffect(() => {
    fetchSavedRulebooks();
    const fetchData = async () => {
      if (doc1 && doc2) {
        setIsLoading(true);
        try {
          setFile1Name(doc1.fileName);
          setFile2Name(doc2.fileName);
          setFile1(doc1.docId);
          setFile2(doc2.docId);

          const req_body = JSON.stringify({
            docId1: doc1.docId,
            docId2: doc2.docId,
            uid: localStorage.getItem('userId'),
            subsystem_id: "MyLib"
          })
          const response = await fetch(CONSTANTS['endpoint_compare'], {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: req_body,
            timeout: 600
          });

          const result = await response.json();
          setDiffs(result.diffs);
          setAnalyzedDiffs([]);
          displayHtml(result.html1, 'html1');
          displayHtml(result.html2, 'html2');
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
        setIsLoading(false);

      }
    };
    fetchData();
  }, [doc1, doc2]);

  const handleRulebookSelect = (rulebookId) => {
    setRulebook(rulebookId);
  };

  return (
    <Box>
      <Header pageTitle="Risk Analysis" showBackHome={true} />

      <div className={styles.compareFilesPage}>
        <div id="main-content" className={styles.mainContent}>
          <div className={styles.column}>
            <label className={styles.fileLabel} id="file1Label">
              {file1Name ? file1Name : "Select a file"}
            </label>
            <iframe id="html1" className={styles.htmlView} title="File 1"></iframe>
          </div>
          <div className={styles.column}>
            <label className={styles.fileLabel} id="file2Label">
              {file2Name ? file2Name : "Select a file"}
            </label>
            <iframe id="html2" className={styles.htmlView} title="File 2"></iframe>
          </div>
        </div>

        <div id="sidebar" className={styles.sidebar}>
          <Button variant="contained" onClick={analyzeAllDiffs} style={{ width: "98%", margin: "1%" }} disabled={isLoading}>Analyze All Changes</Button>
          <Button variant="contained" style={{ width: "98%", margin: "1%" }} onClick={() => setRulebookModalOpen(true)}>
            {rulebook ? "Select Rulebook" : "Select Rulebook"}
          </Button>
          {/* Rulebook Modal using MUI */}
          <Modal
            open={rulebookModalOpen}
            onClose={() => setRulebookModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Select a Rulebook
              </Typography>
              <RadioGroup
                aria-labelledby="rulebook-radio-group-label"
                name="rulebook"
                value={rulebook}
                onChange={(event) => handleRulebookSelect(event.target.value)}
              >
                <List>
                  <div style={{ maxHeight: '200px', overflowY: 'auto', boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}>
                    {savedRulebooks.map((rulebookItem) => (
                      <ListItem key={rulebookItem} disablePadding>
                        <ListItemButton sx={{ width: '100%' }}>
                          <FormControlLabel
                            value={rulebookItem}
                            control={<Radio />}
                            label={rulebookItem}
                            sx={{ width: '100%' }}
                          />
                          {/* Modified delete button with icon and right alignment */}
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}> {/* Added Box for alignment */}
                            <IconButton onClick={() => deleteRulebook(rulebookItem)}> {/* Changed to IconButton */}
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </ListItemButton>
                      </ListItem>
                    ))}</div>
                </List>
              </RadioGroup>
              <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
                Upload New Rulebook
              </Typography>
              <TextField
                type="file"
                accept=".txt"
                onChange={uploadRulebook}
                sx={{ mt: 1, boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.1)' }}
              />
              <Button variant="contained" onClick={() => setRulebookModalOpen(false)} sx={{ mt: 2 }}>
                Close
              </Button>
            </Box>
          </Modal>
          <div id="diff-list" className={styles.diffList}>
            {diffs.map((diff, index) => (
              <div key={index} className={styles.diffItem}>
                <div dangerouslySetInnerHTML={{ __html: diff.summary }} /> <br />
                <Button variant='outlined' style={{ margin: '1%' }} onClick={() => scrollToDiff(index)}>Find</Button>
                <Button variant='outlined' style={{ margin: '1%' }} id={`analyze-risk-button-${index}`} onClick={async () => {
                  const analyzeRiskButton = document.getElementById(`analyze-risk-button-${index}`);
                  analyzeRiskButton.textContent = 'Analyzing...';
                  analyzeRiskButton.disabled = true;
                  const response = await analyzeDiff(diff.before, diff.after, diff.summary_plain, rulebook, index);
                  analyzeRiskButton.textContent = "Re-analyze";
                  analyzeRiskButton.disabled = false;
                  if (response) {
                    const result = response;
                    var risk_level_html = '';
                    if (result.risk_level && result.risk_level.toLowerCase().includes('high')) {
                      risk_level_html = '<b style="color: red;">High</b>';
                    } else if (result.risk_level && result.risk_level.toLowerCase().includes('medium')) {
                      risk_level_html = '<b style="color: #ff9933;">Medium</b>';
                    } else if (result.risk_level && result.risk_level.toLowerCase().includes('low')) {
                      risk_level_html = '<b style="color: green;">Low</b>';
                    } else {
                      risk_level_html = '<b style="color: grey;">Unknown</b>';
                    }
                    const analysis = document.getElementById(`analysis-${index}`);
                    analysis.innerHTML = `</br><b>Risk level:</b> ${risk_level_html}</br></br><b>Comment:</b> ${result.comment}</br></br>`;
                  }
                }}>Analyze Risk</Button>
                < div id={`analysis-${index}`}>
                  {/* ... placeholder for analysis results ... */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>

  );
};

export default CompareFilesPage;