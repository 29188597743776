import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import styles from '../assets/css/pages/LoginPage.module.css';
import Header from '../components/Header';
import { useFiefAuth } from '@fief/fief/react';
import { useLocation } from 'react-router-dom';

function LoginPage() {
  const location = useLocation();
  const fiefAuth = useFiefAuth();
  const [error, setError] = useState(null);

  const login = () => {
    console.log('login pressed');
    setError(null);
    fiefAuth.redirectToLogin(
      `${window.location.protocol}//${window.location.host}/callback`,
    );
  };

  useEffect(() => {
    console.log('location:', location);
    if (location.state && location.state.error) {
      setError(location.state.error);
    }
  }, [location]);

  return (
    <Box className={styles.pageContainer}>
      <Header pageTitle="RCA Manager Login" showBackHome={true} />
      <Box className={styles.mainContainer}>
        {error && (
          <Typography color="error" className={styles.errorMessage}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          className={styles.submitButton}
          onClick={login}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
}

export default LoginPage;
