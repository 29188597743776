import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useFiefAuth } from '@fief/fief/react';
import styles from '../assets/css/pages/UserProfilePage.module.css';
import Header from '../components/Header';

const UserProfilePage = () => {
  const { changePassword } = useFiefAuth();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [apiError, setApiError] = useState('');

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const accessToken = localStorage.getItem('accessToken');

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    if (newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }
    setPasswordError('');

    try {
      await changePassword(accessToken, password, newPassword);
      setPasswordMessage('Password changed successfully');
      setPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      setApiError(
        'An error occurred while changing the password. Please try again later.',
      );
    }
  };

  return (
    <Box className={styles.pageContainer}>
      <Header pageTitle="User Profile" showBackHome={true} />
      <Container component="main" maxWidth="md">
        <div className={styles.mainContainer}>
          <Typography variant="h4" gutterBottom>
            User Profile
          </Typography>
          <form className={styles.form}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email Address"
              value={userInfo.email}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Role"
              value={userInfo.fields.role}
              disabled
            />
          </form>
          <form onSubmit={handlePasswordChange} className={styles.form}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Current Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Confirm New Password"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              error={passwordError !== ''}
              helperText={passwordError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submitButton}
            >
              Change Password
            </Button>
          </form>
          {passwordMessage && (
            <Typography color="primary" variant="body2">
              {passwordMessage}
            </Typography>
          )}
          {apiError && (
            <Typography color="error" variant="body2">
              {apiError}
            </Typography>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default UserProfilePage;
