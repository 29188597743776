import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Redirecting');
    setTimeout(() => {
      const userRole = localStorage.getItem('userRole');
      if (userRole) {
        console.log('User role:', userRole);
        if (userRole.includes('admin')) {
          navigate('/admin');
        } else {
          navigate('/board');
        }
      } else {
        navigate('/login');
      }
    }, 1000);
  }, [navigate]);
  return <p> Redirecting...</p>;
}

export default HomePage;
