import React, { useState } from 'react';
import { AccountCircle, Home, Notifications } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from '../assets/css/components/Header.module.css';
import { ReactComponent as LogoSvg } from '../assets/icons/LogoSvg.svg';
import { useFiefAuth } from '@fief/fief/react';
import { getUserRole } from '../utils/Helperfunction';

function Header({ pageTitle = '', showBackHome = false }) {
  const fiefAuth = useFiefAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    const role = getUserRole();
    if (role === 'admin') {
      navigate('/admin');
    } else {
      navigate('/board');
    }
  };

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    navigate('/logout')
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className={styles.barContainer}>
      <Toolbar className={styles.toolBar}>
        <Box className={styles.leftIcons}>
          <LogoSvg className={styles.logoIcon} />
          {showBackHome && (
            <IconButton
              color="inherit"
              aria-label="back to home"
              className={`${styles.iconButton} ${styles.homeButton}`}
              onClick={handleHomeClick}
            >
              <Home />
            </IconButton>
          )}
        </Box>
        <Typography variant="h6" component="div" className={styles.title}>
          {pageTitle}
        </Typography>
        <Box className={styles.rightIcons}>
          <IconButton color="inherit" className={styles.iconButton}>
            <Notifications />
          </IconButton>
          <IconButton
            color="inherit"
            className={styles.iconButton}
            onClick={handleAccountClick}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
