import { Box, Typography, Button, Divider } from '@mui/material';
import { diffLines } from 'diff';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactDiffViewer from 'react-diff-viewer-continued';
import styles from '../assets/css/pages/ComparisonPage.module.css';
import Header from '../components/Header';
import Loading from '../components/Loading';
import { fetchCompareData } from '../utils/ViewFileHelper';

const ComparisonPage = () => {
  const location = useLocation();
  const { doc1, doc2 } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [doc1Content, setDoc1Content] = useState('');
  const [doc2Content, setDoc2Content] = useState('');
  const [differences, setDifferences] = useState([]);
  const diffViewerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (doc1 && doc2) {
        setLoading(true);
        try {
          const content1 = await fetchCompareData(doc1);
          const content2 = await fetchCompareData(doc2);
          setDoc1Content(content1);
          setDoc2Content(content2);
          const diffResult = diffLines(content1, content2);

          const consolidatedDiffs = [];
          let lineNumber = 1;

          for (let i = 0; i < diffResult.length; i++) {
            const part = diffResult[i];
            if (part.added || part.removed) {
              const diff = {
                lineNumber,
                oldText: part.removed ? part.value.trim() : '',
                newText: part.added ? part.value.trim() : '',
              };

              if (i + 1 < diffResult.length) {
                const nextPart = diffResult[i + 1];
                if ((part.added && nextPart.removed) || (part.removed && nextPart.added)) {
                  diff.oldText = part.removed ? part.value.trim() : nextPart.value.trim();
                  diff.newText = part.added ? part.value.trim() : nextPart.value.trim();
                  i++;
                }
              }

              consolidatedDiffs.push(diff);
            }
            lineNumber += part.count;
          }

          setDifferences(consolidatedDiffs);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [doc1, doc2]);

  const scrollToDiff = (lineNumber) => {
    if (diffViewerRef.current) {
      const lineElements = diffViewerRef.current.querySelectorAll('.react-diff-h84nzi-line-number');
      const targetElement = Array.from(lineElements).find(el => el.textContent.trim() === lineNumber.toString());
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <Box className={styles.pageContainer}>
      <Header pageTitle="Risk Analysis" showBackHome={true} />
      {loading ? (
        <Loading />
      ) : (
        <Box className={styles.mainContainer}>
          <Box className={styles.compareArea}>
            <Box className={styles.titleContainer}>
              <Typography variant="h6" className={styles.fileTitle}>
                {doc1 ? `File 1: ${doc1.fileName}` : 'File 1'}
              </Typography>
              <Typography
                variant="h6"
                className={`${styles.fileTitle} ${styles.rightFileTitle}`}
              >
                {doc2 ? `File 2: ${doc2.fileName}` : 'File 2'}
              </Typography>
            </Box>
            <Box className={styles.viewerArea} ref={diffViewerRef}>
              <ReactDiffViewer
                oldValue={doc1Content}
                newValue={doc2Content}
                splitView={true}
                disableWordDiff={false}
                hideLineNumbers={false}
                className={styles.diffViewer}
              />
            </Box>
          </Box>
          <Box className={styles.sidePanel}>
            <Box className={styles.differencesList}>
              {differences.map((diff, index) => (
                <Box key={index} sx={{ my: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Line {diff.lineNumber}:
                  </Typography>
                  {diff.oldText && (
                    <Typography variant="body2" color="error" gutterBottom>
                      Removed: <br></br>{diff.oldText}
                    </Typography>
                  )}
                  {diff.newText && (
                    <Typography variant="body2" color="success.main" gutterBottom>
                      Added: <br></br>{diff.newText}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => scrollToDiff(diff.lineNumber)}
                    sx={{ mt: 1 }}
                  >
                    Jump to Change
                  </Button>
                  {index < differences.length - 1 && <Divider sx={{ mt: 2 }} />}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ComparisonPage;
