import { Fief } from '@fief/fief';

export const FIEF_SERVER_URL = 'https://dev-fief.idoc.work';
export const FIEF_CLIENT_ID = '27YI5UEF92B4Ue3NPwWUPlueYNAY2E-UrbwNopANLV0';
const FIEF_CLIENT_SECRET = '53MHZl2HpLJA3qCFkBuTumRgrYSTTHuuu_-983fZb7M';

const fief = new Fief({
  baseURL: FIEF_SERVER_URL,
  clientId: FIEF_CLIENT_ID,
  clientSecret: FIEF_CLIENT_SECRET,
});

export default fief;
