import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const validatePIInformation = (data) => {
  let errors = {};

  if (!data.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!data.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!data.salutation) {
    errors.salutation = 'Salutation is required';
  }
  if (!data.natureOfEmployment) {
    errors.natureOfEmployment = 'Nature of employment is required';
  }
  if (!data.hostingFaculty) {
    errors.hostingFaculty = 'Hosting faculty/RI is required';
  }

  return errors;
};

const PIInformationSection = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      piInformation: {
        ...prevFormData.piInformation,
        [name]: value,
      },
      errors: {
        ...prevFormData.errors,
        piInformation: {
          ...prevFormData.errors.piInformation,
          [name]: '', // Clear the error for the respective field
        },
      },
    }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">PI Information</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">NUS PI Name:</Typography>
        <FormHelperText>
          Ensure to input the name of the NUS PI for the project. It should NOT
          be the name of admin/research staff assisting PI in the submission of
          this request.
        </FormHelperText>
        <TextField
          fullWidth
          label="First name (required)"
          name="firstName"
          value={formData.piInformation.firstName || ''}
          onChange={handleChange}
          required
          margin="normal"
          error={!!formData.errors.piInformation.firstName}
          helperText={formData.errors.piInformation.firstName}
        />
        <TextField
          fullWidth
          label="Last name (required)"
          name="lastName"
          value={formData.piInformation.lastName || ''}
          onChange={handleChange}
          required
          margin="normal"
          error={!!formData.errors.piInformation.lastName}
          helperText={formData.errors.piInformation.lastName}
        />
        <FormControl
          fullWidth
          margin="normal"
          required
          error={!!formData.errors.piInformation.salutation}
        >
          <InputLabel>Salutation (required)</InputLabel>
          <Select
            name="salutation"
            value={formData.piInformation.salutation || ''}
            label="Salutation (required)"
            onChange={handleChange}
          >
            <MenuItem value="Prof">Professor</MenuItem>
            <MenuItem value="AsstProf">Assistant Professor</MenuItem>
            <MenuItem value="AssocProf">Associate Professor</MenuItem>
            <MenuItem value="Dr">Dr</MenuItem>
          </Select>
          <FormHelperText>
            {formData.errors.piInformation.salutation}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          required
          error={!!formData.errors.piInformation.natureOfEmployment}
        >
          <InputLabel>NUS PI’s nature of employment</InputLabel>
          <Select
            name="natureOfEmployment"
            value={formData.piInformation.natureOfEmployment || ''}
            label="NUS PI’s nature of employment"
            onChange={handleChange}
          >
            <MenuItem value="Full Time">Full Time</MenuItem>
            <MenuItem value="Part Time">Part Time / Adjunct</MenuItem>
            <MenuItem value="Visiting">Visiting</MenuItem>
            <MenuItem value="Distinguished">
              Distinguished Professorship
            </MenuItem>
          </Select>
          <FormHelperText>
            {formData.errors.piInformation.natureOfEmployment}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          required
          error={!!formData.errors.piInformation.hostingFaculty}
        >
          <InputLabel>Hosting Faculty / RI</InputLabel>
          <Select
            name="hostingFaculty"
            value={formData.piInformation.hostingFaculty || ''}
            label="Hosting Faculty / RI"
            onChange={handleChange}
          >
            <MenuItem value="Engineering">
              College of Design and Engineering
            </MenuItem>
            <MenuItem value="Medicine">
              Yong Loo Lin School of Medicine
            </MenuItem>
            <MenuItem value="Science">Faculty of Science</MenuItem>
            <MenuItem value="PublicHealth">
              Saw Swee Hock School of Public Health
            </MenuItem>
          </Select>
          <FormHelperText>
            {formData.errors.piInformation.hostingFaculty}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

export { PIInformationSection, validatePIInformation };
