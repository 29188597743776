import React from 'react';
import styles from '../assets/css/pages/NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.notFoundTitle}>404 Not Found</h1>
      <p className={styles.notFoundText}>
        The page you are looking for does not exist.
      </p>
    </div>
  );
};

export default NotFoundPage;
