import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../assets/css/pages/DashboardPage.module.css';
import FileTable from '../components/FileTable';
import Header from '../components/Header';
import Loading from '../components/Loading';
import CONSTANTS from '../config/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import {
  deleteFile,
  getUserUploadedFiles,
  uploadFile,
} from '../utils/Helperfunction';

function DashboardPage() {
  const navigate = useNavigate();
  const [fileListData, setFileListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [comparisonFiles, setComparisonFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredFiles = fileListData.filter((file) =>
    file.fileName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    async function fetchFiles() {
      setLoading(true);
      try {
        const files = await getUserUploadedFiles();
        setFileListData(files);
      } catch (error) {
        console.error('Failed to fetch files:', error);
      }
      setLoading(false);
    }
    fetchFiles();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.MuiBottomNavigation-root')) {
        return;
      }
      if (
        !event.target.closest(`.${styles.fileItem}`) &&
        !event.target.closest('button')
      ) {
        setSelectedFile(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectFile = (file) => {
    setSelectedFile(file);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setLoading(true);
      try {
        const endpoint = CONSTANTS['endpoint_upload_file'];
        await uploadFile(files, endpoint);
        const updatedFiles = await getUserUploadedFiles();
        setFileListData(updatedFiles);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
      setLoading(false);
    }
  };

  const handleDownloadFile = (docId, fileName, event) => {
    event.stopPropagation();

    // 检测文件扩展名
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // 根据文件类型设置不同的下载逻辑
    let fileUrl;
    if (fileExtension === 'docx' || fileExtension === 'pdf') {
      // 如果是DOCX或PDF，直接使用指定的下载链接
      fileUrl = `/path/to/download/${docId}.${fileExtension}`;
    } else {
      // 如果不是已知类型，可以选择进行错误处理或者使用默认处理
      console.error('Unsupported file type for download');
      return;
    }

    // 创建链接元素并触发下载
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteFile = async (docId, e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const endpoint = CONSTANTS['endpoint_delete_file'];
      await deleteFile(docId, endpoint);
      const updatedFiles = await getUserUploadedFiles();
      setFileListData(updatedFiles);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
    setLoading(false);
  };

  return (
    <Box className={styles.dashboardPage}>
      <Header pageTitle="Dashboard" />
      <Box className={styles.contentWrapper}>
        <Box component="main" className={styles.mainContent}>
          {loading && <Loading />}
          <Box className={styles.rcaBtnWrapper}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => navigate('/rca-form')}
            >
              RCA Form
            </Button>
          </Box>
          <Box className={styles.uploadBtnWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInputRef.current.click()}
              fullWidth
            >
              Upload File
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              hidden
              onChange={handleFileUpload}
            />
          </Box>
          <Box className={styles.searchFieldWrapper}>
            <TextField
              fullWidth
              label="Search file"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className={styles.fileTableWrapper}>
            <FileTable
              files={filteredFiles}
              selectedFile={selectedFile}
              setFileListData={setFileListData}
              handleSelectFile={handleSelectFile}
              handleDownloadFile={handleDownloadFile}
              handleDeleteFile={handleDeleteFile}
              comparisonFiles={comparisonFiles}
              setComparisonFiles={setComparisonFiles}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardPage;
