import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const validateCollaboratorInformation = (collaborators) => {
  const errors = collaborators.map((collaborator) => {
    const collaboratorErrors = {};
    if (!collaborator.entityName) {
      collaboratorErrors.entityName = 'Entity name is required.';
    }
    if (!collaborator.country) {
      collaboratorErrors.country = 'Country is required.';
    }
    if (!collaborator.abbreviation) {
      collaboratorErrors.abbreviation = 'Abbreviation is required.';
    }
    return collaboratorErrors;
  });

  return errors;
};

const CollaboratorInformationSection = ({ formData, setFormData }) => {
  const handleInputChange = (index, e) => {
    const updatedCollaborators = formData.collaboratorInformation.map(
      (collaborator, i) => {
        if (index === i) {
          return { ...collaborator, [e.target.name]: e.target.value };
        }
        return collaborator;
      },
    );

    const updatedErrors = formData.errors.collaboratorInformation.map(
      (error, i) => {
        if (index === i) {
          return { ...error, [e.target.name]: '' };
        }
        return error;
      },
    );

    setFormData({
      ...formData,
      collaboratorInformation: updatedCollaborators,
      errors: {
        ...formData.errors,
        collaboratorInformation: updatedErrors,
      },
    });
  };

  const handleNumberOfCollaboratorsChange = (e) => {
    const numberOfCollaborators = parseInt(e.target.value, 10);
    const newCollaborators = Array.from(
      { length: numberOfCollaborators },
      () => ({
        entityName: '',
        country: '',
        abbreviation: '',
      }),
    );
    const newErrors = Array.from({ length: numberOfCollaborators }, () => ({
      entityName: '',
      country: '',
      abbreviation: '',
    }));
    setFormData({
      ...formData,
      collaboratorInformation: newCollaborators,
      errors: {
        ...formData.errors,
        collaboratorInformation: newErrors,
      },
    });
  };

  const collaboratorInputs = formData.collaboratorInformation.map(
    (collaborator, index) => (
      <Paper key={index} variant="outlined" sx={{ p: 2, mt: 2 }}>
        <Typography variant="h5">Collaborator Entity #{index + 1}</Typography>
        <TextField
          fullWidth
          label="Entity name (required)"
          name="entityName"
          value={collaborator.entityName}
          onChange={(e) => handleInputChange(index, e)}
          margin="normal"
          required
          error={
            !!(
              formData.errors.collaboratorInformation &&
              formData.errors.collaboratorInformation[index] &&
              formData.errors.collaboratorInformation[index].entityName
            )
          }
          helperText={
            formData.errors.collaboratorInformation &&
            formData.errors.collaboratorInformation[index] &&
            formData.errors.collaboratorInformation[index].entityName
          }
        />

        <TextField
          fullWidth
          label="Country (required)"
          name="country"
          value={collaborator.country}
          onChange={(e) => handleInputChange(index, e)}
          margin="normal"
          required
          error={
            !!(
              formData.errors.collaboratorInformation &&
              formData.errors.collaboratorInformation[index] &&
              formData.errors.collaboratorInformation[index].country
            )
          }
          helperText={
            formData.errors.collaboratorInformation &&
            formData.errors.collaboratorInformation[index] &&
            formData.errors.collaboratorInformation[index].country
          }
        />

        <TextField
          fullWidth
          label="Abbreviation (required)"
          name="abbreviation"
          value={collaborator.abbreviation}
          onChange={(e) => handleInputChange(index, e)}
          margin="normal"
          required
          error={
            !!(
              formData.errors.collaboratorInformation &&
              formData.errors.collaboratorInformation[index] &&
              formData.errors.collaboratorInformation[index].abbreviation
            )
          }
          helperText={
            formData.errors.collaboratorInformation &&
            formData.errors.collaboratorInformation[index] &&
            formData.errors.collaboratorInformation[index].abbreviation
          }
        />
      </Paper>
    ),
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Collaborator Information</Typography>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Number of Collaborator Entities</InputLabel>
        <Select
          name="numberOfCollaborators"
          value={formData.collaboratorInformation.length.toString()}
          onChange={handleNumberOfCollaboratorsChange}
          label="Number of Collaborator Entities"
        >
          {[1, 2, 3, 4].map((number) => (
            <MenuItem key={number} value={number}>
              {number} Collaborator{number > 1 ? 's' : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {collaboratorInputs}
    </Box>
  );
};

export { CollaboratorInformationSection, validateCollaboratorInformation };
