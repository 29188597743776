import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownText = ({ text }) => {
  return (
    <div className="markdown-text">
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};

export default MarkdownText;
