import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';

const validateResearchArea = (researchArea) => {
  if (!researchArea.value) {
    return { researchArea: 'Selecting a research domain is required.' };
  }
  return {};
};

const ResearchAreaSection = ({ formData, setFormData }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    const selectedDomain = researchDomains.find(
      (domain) => domain.value === value,
    );
    setFormData((prevData) => ({
      ...prevData,
      researchArea: selectedDomain || { value: '', label: '' },
      errors: {
        ...prevData.errors,
        researchArea: {
          researchArea: '', // Clear the error for researchArea
        },
      },
    }));
  };

  const researchDomains = [
    { value: 'domain1', label: 'Health Sciences' },
    { value: 'domain2', label: 'Engineering and Technology' },
    { value: 'domain3', label: 'Natural Sciences' },
    { value: 'domain4', label: 'Social Sciences' },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Research Area</Typography>
      <FormControl
        fullWidth
        error={!!formData.errors.researchArea.researchArea}
        sx={{ mt: 2 }}
      >
        <InputLabel>
          Select the most relevant RIE2025 research domains
        </InputLabel>
        <Select
          value={formData.researchArea.value || ''}
          onChange={handleChange}
          displayEmpty
        >
          {researchDomains.map((domain) => (
            <MenuItem key={domain.value} value={domain.value}>
              {domain.label}
            </MenuItem>
          ))}
        </Select>
        {formData.errors.researchArea.researchArea && (
          <FormHelperText>
            {formData.errors.researchArea.researchArea}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export { ResearchAreaSection, validateResearchArea };
